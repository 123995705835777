body {
  padding: 0;
  margin: 0;
  color: white;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "calt" 1, "zero" 1, "ss01" 1, "ss02" 1, "ss08" 1;
  background-color: #0d0d0d;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/InterVariable.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/InterVariable-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

/* Authenticator modal styling */
[data-amplify-authenticator][data-variation="modal"] {
  background-color: #000 !important; /* Match theme background */
  color: white !important; /* Ensure text is visible */
}

/* Router content styling */
[data-amplify-router] {
  border-radius: 10px;
}

[data-amplify-router-content] {
  background-color: #0d0d0d;
  color: white;
  border-radius: 10px;
}

/* Label styling */
.amplify-label {
  color: white !important;
}

/* Button styling */
.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small:hover {
  background-color: black; /* Green background on hover */
  color: white; /* White text on hover */
}

.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small {
  color: white; /* White text */
}

.amplify-button--primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
  color: white !important; /* Ensure text is visible */
}

/* Forgot password styling */
[data-amplify-authenticator-forgotpassword] { 
  color: white !important; /* Ensure text is visible */
  background-color: #0d0d0d !important; /* Match theme background */
  border-radius: 10px;
}

/* Heading styling */
.amplify-heading  { 
  color: white !important; /* Ensure text is visible */
}

/* Field group control styling */
.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  background-color: #333; /* Darker background to match theme */
  color: white; /* Ensure text is visible */
}

/* Email input field styling */
input.amplify-input.amplify-field-group__control[aria-invalid="false"][autocomplete="username"][required][type="email"][placeholder][name="username"][autocapitalize="off"] {
  background-color: #000 !important; /* Black background */
  color: white !important; /* White text */
  border: 1px solid #444 !important; /* Optional: border color to match theme */
}

/* Password input field styling */
input.amplify-input.amplify-field-group__control[aria-invalid="false"][autocomplete="current-password"][required][type="password"][placeholder][name="password"][autocapitalize="off"],
input.amplify-input.amplify-field-group__control[aria-invalid="false"][autocomplete="current-password"][required][type="text"][placeholder][name="password"][autocapitalize="off"] {
  background-color: #000 !important; /* Black background */
  color: white !important; /* White text */
  border: 1px solid #444 !important; /* Optional: border color to match theme */
}

/* Password hide / show button styling */
.amplify-button.amplify-field-group__control.amplify-field__show-password {
  border-color: transparent !important; /* Make border transparent */
}

/* Placeholder styling */
.amplify-input.amplify-field-group__control::placeholder {
  color: #aaa !important; /* Placeholder text color for readability */
}

/* Focus styling for email input */
input.amplify-input.amplify-field-group__control[aria-invalid="false"][autocomplete="username"][required][type="email"][placeholder][name="username"][autocapitalize="off"]:focus {
  border-color: #007bff !important; /* Theme blue border color on focus */
  outline: none; /* Remove default outline */
}

/* Focus styling for password input */
input.amplify-input.amplify-field-group__control[aria-invalid="false"][autocomplete="current-password"][required][type="password"][placeholder][name="password"][autocapitalize="off"]:focus,
input.amplify-input.amplify-field-group__control[aria-invalid="false"][autocomplete="current-password"][required][type="text"][placeholder][name="password"][autocapitalize="off"]:focus {
  border-color: #007bff !important; /* Theme blue border color on focus */
  outline: none; /* Remove default outline */
}

[data-amplify-form] { 
  color: white !important; /* Ensure text is visible */
  background-color: #0d0d0d !important; /* Match theme background */
  border-radius: 10px;

}

/* Code block styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: #1e1e1e; /* Match theme's dark mode */
  color: #f8f8f2; /* Ensure text is visible */
}